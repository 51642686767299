<title>آزمایشگاه زنجیره هوشمند</title>
<div>
    <h5>{{'ربات در چه صورت متوقف شود ؟' }}</h5>
    <div class="btn-group stopSettings" role="group" aria-label="Stop On Yellow" (change)="onStopModeRadioButtonChanged($event)">
        <input type="radio" value="yes" [formControl]="autoGenStopSettingFormControl" class="btn-check" [id]="'stopZeroYes'+formName" autocomplete="off">
        <label class="btn btn-outline-primary" [for]="'stopZeroYes'+formName" [ngClass]="{'active' : autoGenStopSettingFormControl.value == 'yes'}">{{'مشاهده تراکنش' | translate}}</label>

        <input type="radio" value="no" [formControl]="autoGenStopSettingFormControl" class="btn-check" [id]="'stopZeroNo'+formName" autocomplete="off">
        <label class="btn btn-outline-primary" [for]="'stopZeroNo'+formName" [ngClass]="{'active' : autoGenStopSettingFormControl.value == 'no'}">{{'مشاهده دارایی' | translate}}</label>
    </div>
    
    <br>
    <br>

    <h5>{{'نحوه گذر از صفحات' | translate}}</h5>
    <div class="btn-group pageSelectionSetting" role="group" aria-label="Page Selection Setting" (change)="onPageSelectionTypeChanged($event)">
        <input type="radio" value="random" [formControl]="autoGenPageSelectionFormControl" class="btn-check" [id]="'randomPageSelection'+formName" autocomplete="off">
        <label class="btn btn-outline-primary" [for]="'randomPageSelection'+formName" [ngClass]="{'active' : autoGenPageSelectionFormControl.value == pageSelectionTypes.random }">{{'تصادفی' | translate}}</label>

        <input type="radio" value="incrementing" [formControl]="autoGenPageSelectionFormControl" class="btn-check" [id]="'incrementingPageSelection'+formName" autocomplete="off">
        <label class="btn btn-outline-primary" [for]="'incrementingPageSelection'+formName" [ngClass]="{'active' : autoGenPageSelectionFormControl.value == pageSelectionTypes.incrementing }">{{'یک شماره بیشتر' | translate}}</label>

        <input type="radio" value="decrementing" [formControl]="autoGenPageSelectionFormControl" class="btn-check" [id]="'decrementingPageSelection'+formName" autocomplete="off">
        <label class="btn btn-outline-primary" [for]="'decrementingPageSelection'+formName" [ngClass]="{'active' : autoGenPageSelectionFormControl.value == pageSelectionTypes.decrementing}">{{' یک شماره کمتر ' | translate}}</label>
    </div>
</div>