<div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
    <div style="width: 90%">
        <div class="d-flex align-items-center">
            <img src="../../../assets/block.webp" style="margin-right: 10px;" width="50px" height="50px"> <!--Cube by Icons8-->
            <div>
                <h2>{{'اکسپلورر شبکه بیت‌کوین' | translate}}</h2>
                
            </div>
        </div>

        <div class="m-4"></div>
        <h2>{{'تمامی رفتارهای مالی مربوط به هر آدرس در شبکه بیت‌کوین را میتوانید با ارسال آن در فیلد زیر مشاهده کنید ' | translate}}</h2>
    
        <div class="m-5"></div>
    
        <div>
            <form (ngSubmit)="onSubmitSearch()">
                <div class="d-flex formSection">
                    <input type="text" class="w-100" style="font-size: xx-large; color: cyan" name="searchQuery" [(ngModel)]="searchQuery">
                    <input type="submit" class="btn btn-primary" [value]="'explorer.index.searchBtn' | translate" style="margin-left: 10px"> 
                </div>
            </form>
        </div>

        <h3 *ngIf="didSearch">{{searchResultHeader}}</h3>
        
        <div style="margin: 20px;"></div>

        <div id="explorerSearchResults">
            <table class="mobileScrollableTable table table-hover searchResultTable">
                <tbody>
                    <tr class="searchResultRow" *ngFor="let res of searchResults;" [routerLink]="res.link" style="cursor: pointer">
                        <td>
                            <img [src]="res.getImgSrc()" width="70px" style="margin-right: 15px;"> <h4 style="display: inline">{{res.networkType}}: {{res.searchType}}</h4>
                        </td>

                        <td>
                            <h4>{{res.text}}</h4>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="margin-bottom: 200px;"></div>
    </div>
</div>
