<div class="container">

  <div class="m-2"></div>

  <div *ngIf="errorCallingApi" class="alert alert-danger">{{'explorer.address.errorCallingApi' | translate}}</div>
  <h2>{{'اکسپلورر' | translate}}</h2>

  <div class="mainInnerDiv">
    <qrcode [qrdata]="address" [width]="300" [errorCorrectionLevel]="'M'" class="m-5"></qrcode>
    <table class="table mobileScrollableTable">
        <tbody>
          <tr>
            <th scope="row">{{'آدرس' | translate}}</th>
            <td>{{addressModel?.address}}</td>
          </tr>
          <tr>
            <th scope="row">{{'شبکه' | translate}}</th>
            <td>{{isTestnet ? "تست‌نت" : "اصلی"}}</td>
          </tr>
          <tr>
            <th scope="row">{{'فرمت' | translate}}</th>
            <td>
              <h5><span class="badge" [ngClass]="{ 'bg-danger': addressModel?.getFormat() == 'legacy', 'bg-primary': addressModel?.getFormat() == 'segwit', 'bg-success': addressModel?.getFormat() == 'bech32'}">{{addressModel?.getDisplayFormat()}}</span></h5>
            </td>
          </tr>
          <tr>
            <th scope="row">{{'میزان تراکنش' | translate}}</th>
            <td>{{addressModel?.transactions}}</td>
          </tr>
          <tr>
            <th scope="row">{{' میزان واریزیها' | translate}}</th>
            <td>8{{addressModel?.getBtcReceived()}} بیت‌کوین (${{priceService.btcToUsd(addressModel?.getBtcReceived())}})</td>
          </tr>
          <tr>
            <th scope="row">{{'میزان ارسال پول' | translate}}</th>
            <td>{{addressModel?.getBtcSent()}} بیت‌کوین (${{priceService.btcToUsd(addressModel?.getBtcSent())}})</td>
          </tr>
          <tr>
            <th scope="row">{{'explorer.address.table.balance' | translate}}</th>
            <td>{{' '}} بیت‌کوین (${{priceService.btcToUsd(addressModel?.getBtcBalance())}})</td>
          </tr>
        </tbody>
      </table>
  </div>

  <h2>{{'تراکنش ها' | translate}}</h2>

  <app-explorer-transaction-list [transactions]="transactions" [isTestnet]="isTestnet"></app-explorer-transaction-list>

<div style="margin-top: 100px;"></div>