
<div class="d-flex align-items-center w-100 flex-column justify-content-center">

    <div class="mt-5"></div>
    <img src="../../assets/failed.webp">
    <h1>{{'tooFar.topHeader' | translate}}</h1>
    <h2>{{'tooFar.topSubheader' | translate}}</h2>

    <div class="mt-4"></div>

    <a class="btn btn-primary btn-lg" routerLink="/random/">{{'tooFar.randomBtn' | translate}}</a>
    <div class="m-2"></div>
    <a class="btn btn-primary btn-lg" routerLink="/testnet-random/">{{'tooFar.randomTestnetBtn' | translate}}</a>
</div>