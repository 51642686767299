<div id="singleAddressContainer">

    <div id="singleAddressSettings">

        <div class="m-4"></div>

        <app-network-type-selector (itemChanged)="networkTypeChanged($event)"></app-network-type-selector>

        <div class="m-4"></div>

        <app-address-type-selector (itemChanged)="addressTypeChanged($event)"></app-address-type-selector>
        
        <div class="m-4"></div>

        <app-qr-code-type-selector (itemChanged)="qrCodeTypeChanged($event)" ></app-qr-code-type-selector>

        <div class="m-5"></div>

        <button class="btn btn-lg btn-outline-primary" (click)="makeNewKeyset()">{{'ساخت کیف پول' | translate}}</button>
        <div style="margin-top: 5px;" class="alert alert-danger testnetScamWarning" *ngIf="getIsTestnet()">
       
        </div>
    </div>

    <div id="qrCodeContainer">

        <app-qr-code-with-border id="addressQrSection" [headerText]="'walletGenerator.singleKeyset.addressHeaderText' | translate" [qrData]="model.address" [errorCorrectionLevel]="getQrCodeType()" borderColor="green"></app-qr-code-with-border>
        
        <div style="margin-top: 15px"></div>
        
        <app-qr-code-with-border [headerText]="'walletGenerator.singleKeyset.privateKeyHeaderText' | translate" [qrData]="model.privateKey" borderColor="darkred" [errorCorrectionLevel]="getQrCodeType()"></app-qr-code-with-border>
    </div>
</div>
<div style="margin-top: 100px"></div>