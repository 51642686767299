<title>8key</title>
<nav class="navbar navbar-expand-md">
    <div class="titleBar navbar-brand nav-item nav-left">
        <a routerLink="/" id="homeLink">
            آزمایشگاه زنجیره هوشمند 
        </a>
    </div>
    <div class="collapse navbar-collapse" id="toggleMobileMenu">
        <ul class="navbar-nav ms-auto text-end">
		<li class="navLi"><a routerLink="/about" class="">{{'درباره ما'}}</a></li>
            <li class="navLi"><a routerLink="/explorer" class="">{{'اکسپلور'}}</a></li>
            <li class="navLi"><a routerLink="/wallet-generator" class="">{{'ولت ساز'}}</a></li>
			<li class="navLi"><a routerLink="/donate" class="">{{' آموزش'}}</a></li>
			<li class="navLi"><a routerLink="#" class="">{{'سیگنالها'}}</a></li>
        </ul>
    </div>
</nav>
