<div *ngIf="showPrice" style="height: 100%; display: flex" id="priceOuter">
    <div style="display: flex; align-items: center;" id="priceInner">
        <img src="../../assets/bitcoin-logo.webp" width="50px">
        <div style="margin-right: 5px;"></div>
        <div style="font-family: Roboto, 'Helvetica Neue', sans-serif;" id="priceValue">${{priceModel.usdPrice}}</div>
        <div style="margin-right: 5px;"></div>

        <div style="display: flex; align-items: center;">
            <div style="font-family: Roboto, 'Helvetica Neue', sans-serif; word-wrap: break-word;" id="percentageChangeValue" [style.color]="priceModel.getColor()">{{priceModel.getArrow()}}{{priceModel.changePercentage}}%</div>
        </div>
    </div>
</div>
