<h2>{{'درجه تصحیح خطا در کیو آر کد' | translate}}</h2>
<div class="btn-group" role="group" (change)="onRadioButtonChanged()">
    <input type="radio" [name]="radioName" [formControl]="qrFormControl" [value]="qrCodeTypes.high" class="btn-check" [id]="'qrHighRadio'+radioName" autocomplete="off">
    <label class="btn btn-outline-primary" [ngClass]="{'active' : qrFormControl.value == qrCodeTypes.high}" [for]="'qrHighRadio'+radioName">{{'بسیار قوی' | translate}}</label>
  
    <input type="radio" [name]="radioName" [formControl]="qrFormControl" [value]="qrCodeTypes.quartile" class="btn-check" [id]="'qrQuartileRadio'+radioName" autocomplete="off">
    <label class="btn btn-outline-primary" [ngClass]="{'active' : qrFormControl.value == qrCodeTypes.quartile}" [for]="'qrQuartileRadio'+radioName">{{'قوی' | translate}}</label>

    <input type="radio" [name]="radioName" [formControl]="qrFormControl" [value]="qrCodeTypes.medium" class="btn-check" [id]="'qrMediumRadio'+radioName" autocomplete="off">
    <label class="btn btn-outline-primary" [ngClass]="{'active' : qrFormControl.value == qrCodeTypes.medium}" [for]="'qrMediumRadio'+radioName">{{'معمولی' | translate}}</label>

    <input type="radio" [name]="radioName" [formControl]="qrFormControl" [value]="qrCodeTypes.low" class="btn-check" [id]="'qrLowRadio'+radioName" autocomplete="off">
    <label class="btn btn-outline-primary" [ngClass]="{'active' : qrFormControl.value == qrCodeTypes.low}" [for]="'qrLowRadio'+radioName">{{'ضعیف' | translate}}</label>
</div>