
<div class="d-flex align-items-center w-100 flex-column justify-content-center">

    <div class="mt-1"></div>  <h5 id="maxPageNum" style="max-width: 80%;"></h5>
<h5 id="maxPageNum" style="max-width: 80%;">{{maxPageNumber}}</h5>
    <div *ngIf="balanceApi.errorCallingApi">
        <div class="alert alert-danger" role="alert">
            متاسفانه مشکلی در پردازش میزان دارایی بوجود آمده لطفا با وی پی ان مجدد تست کنید !
        </div>          
    </div>
<p><p><p><p>
    <div class="d-flex align-items-center w-100 justify-content-center">
        <app-current-page-stats-display [redCounter]="this.redCounter" [yellowCounter]="this.yellowCounter" [greenCounter]="this.greenCounter"></app-current-page-stats-display>
    </div>

    

    <div class="m-3"></div>

    <app-auto-gen [isTestnet]="isTestnet" [pageNumber]="pageNumber"></app-auto-gen>
    <app-auto-gen-settings-display></app-auto-gen-settings-display>

    <div *ngIf="isLoading && !autoGenService.autoModeActive" class="loadingDiv" style="height: 50px">{{'keyspage.loading'}}</div>
    <app-pagebuttons *ngIf="!isLoading && !autoGenService.autoModeActive" style="width: 50%;" class="pageBtns"></app-pagebuttons>

    <div class="m-3"></div>

    <div style="display: flex; justify-content: center; width: 100%;" class="tableWrapper">
        <table style="border-spacing: 10px 7px; border-collapse: separate; width: 50%;" class="keysTable">
            <tr *ngFor="let model of keys; let index = index" class="tableRow">
                <td class="desktopTd" style="padding: 10px 0px;" [style.border-left]="model.borderColor" [ngStyle]="{'display': model.display}">
                    <a [style.color]="model.privateKeyTextColor" [routerLink]="model.getWifExplorerUrl('uncompressed')">{{model.privateKey}}</a>
                    <br>
                    <a [style.color]="model.privateKeyTextColor" [routerLink]="model.getWifExplorerUrl('compressed')">{{model.privateKeyCompressed}}</a>
                </td>

                <td class="desktopTd" [ngStyle]="{'display': model.display}">
                    <a [style.color]="model.legacyColor" [routerLink]="model.getAddressExplorerUrl('legacy')" class="{{model.legacy}}">{{model.legacy}}</a>
                    <br>
                    <a [style.color]="model.legacyCompressedColor" [routerLink]="model.getAddressExplorerUrl('legacyCompressed')" class="{{model.legacyCompressed}}">{{model.legacyCompressed}}</a>
                </td>

                <td class="desktopTd" [ngStyle]="{'display': model.display}">
                    <a [style.color]="model.segwitColor" [routerLink]="model.getAddressExplorerUrl('segwit')" class="{{model.segwit}}">{{model.segwit}}</a>
                    <br>
                    <a [style.color]="model.bech32Color" [routerLink]="model.getAddressExplorerUrl('bech32')" class="{{model.bech32}}">{{model.bech32}}</a>
                </td>

        
                <td class="desktopTd" [ngStyle]="{'display': model.display}">
                    <p>{{model.stats}}</p>
                </td>
            
                <td class="mobileTd" [style.border-center]="model.borderColor" [ngStyle]="{'display': model.display}">
                    <a [routerLink]="model.getWifExplorerUrl('uncompressed')">{{model.privateKey}}</a>
                    <div style="margin-top: 0px;">
					</div>
                    {{model.stats}}
                </td>
            </tr>
        </table>
		<br>
		        <table style="border-spacing: 10px 7px; border-collapse: separate; width: 50%;" class="keysTable">
            <tr *ngFor="let model of keys; let index = index" class="tableRow">
                <td class="desktopTd" style="padding: 10px 0px;" [style.border-left]="model.borderColor" [ngStyle]="{'display': model.display}">
                    <a [style.color]="model.privateKeyTextColor" [routerLink]="model.getWifExplorerUrl('uncompressed')">{{model.privateKey}}</a>
                    <br>
                    <a [style.color]="model.privateKeyTextColor" [routerLink]="model.getWifExplorerUrl('compressed')">{{model.privateKeyCompressed}}</a>
                </td>

                <td class="desktopTd" [ngStyle]="{'display': model.display}">
                    <a [style.color]="model.legacyColor" [routerLink]="model.getAddressExplorerUrl('legacy')" class="{{model.legacy}}">{{model.legacy}}</a>
                    <br>
                    <a [style.color]="model.legacyCompressedColor" [routerLink]="model.getAddressExplorerUrl('legacyCompressed')" class="{{model.legacyCompressed}}">{{model.legacyCompressed}}</a>
                </td>

                <td class="desktopTd" [ngStyle]="{'display': model.display}">
                    <a [style.color]="model.segwitColor" [routerLink]="model.getAddressExplorerUrl('segwit')" class="{{model.segwit}}">{{model.segwit}}</a>
                    <br>
                    <a [style.color]="model.bech32Color" [routerLink]="model.getAddressExplorerUrl('bech32')" class="{{model.bech32}}">{{model.bech32}}</a>
                </td>

        
                <td class="desktopTd" [ngStyle]="{'display': model.display}">
                    <p>{{model.stats}}111</p>
                </td>
            
                <td class="mobileTd" [style.border-center]="model.borderColor" [ngStyle]="{'display': model.display}">
                    <a  [routerLink]="model.getWifExplorerUrl('compressed')">{{model.privateKeyCompressed}}</a>
                    <div style="margin-top: 0px;">
                     
					</div>
					{{model.stats}}
                </td>
            </tr>
        </table>
    </div>

    <h3 *ngIf="shouldHideUnusedKeys()">(+{{unusedKeyCount}}) {{'keyspage.hidden' | translate}}</h3>

    <ng-container *ngIf="!isEmptyPage() && shouldHideUnusedKeys() || !shouldHideUnusedKeys()">
        <app-pagebuttons *ngIf="!isLoading && !autoGenService.autoModeActive" style="width: 70%;" class="pageBtns"></app-pagebuttons>
        <div *ngIf="isLoading && !autoGenService.autoModeActive" class="loadingDiv" style="height: 50px; text-align: center;">{{'keyspage.loading' | translate}}</div>

        <div style="margin-top: 25px;"></div>
        <app-auto-gen [isTestnet]="isTestnet" [pageNumber]="pageNumber"></app-auto-gen>
    </ng-container>

    <div class="m-5"></div> 
</div>
