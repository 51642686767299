<div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
    <div style="width: 90%;">
        <h1>{{'کیف پول ساز اتوماتیک' | translate}}</h1>

        


        <ul id="walletGenNav" ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs" orientation="horizontal">
            <li [ngbNavItem]="1" [destroyOnHide]="false">
              <a ngbNavLink>{{'کیف پول تکی' | translate}}</a>
              <ng-template ngbNavContent>
                <app-single-keyset></app-single-keyset>
              </ng-template>
            </li>

            <li [ngbNavItem]="2" [destroyOnHide]="false">
              <a ngbNavLink>{{'ساخت تعدادی کیف پول' | translate}}</a>
              <ng-template ngbNavContent>
                <app-bulk-generate></app-bulk-generate>
              </ng-template>
            </li>

            <li [ngbNavItem]="3" [destroyOnHide]="false">
              <a ngbNavLink>{{'کیف پول با متود منامومیک' | translate}}</a>
              <ng-template ngbNavContent let-active>
                <app-mnemonic></app-mnemonic>
              </ng-template>
            </li>
          </ul>
          
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>
