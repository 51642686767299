<div class="container">
    <h1>{{'aboutTestnetScams.topHeader' | translate}}</h1>

    <p class="descParagraph">
        {{'aboutTestnetScams.desc1' | translate}}
    </p>

    <p class="descParagraph" [innerHTML]="'aboutTestnetScams.desc2' | translate">
        
    </p>
    
    <br>

    <h2>{{'aboutTestnetScams.header2' | translate}}</h2>
    <p class="descParagraph">
        {{'aboutTestnetScams.desc3' | translate}}
    </p>

    <br>

    <h2>{{'aboutTestnetScams.howToAvoidHeader' | translate}}</h2>

    <p class="descParagraph">
        {{'aboutTestnetScams.howToAvoidDesc' | translate}}
    </p>

    <ul class="descList">
        <li [innerHTML]=" 'aboutTestnetScams.descListReal' | translate "></li>
        <li [innerHTML]=" 'aboutTestnetScams.descListScam' | translate "></li>
    </ul>
    
    <br>

    <h2>Example</h2>

    <table class="table table-borderless exampleTable mobileScrollableTable">
        <tbody>
            <tr>
                <th class="text-lime">{{'aboutTestnetScams.exampleTableReal' | translate}}</th>
                <th class="text-red">{{'aboutTestnetScams.exampleTableScam' | translate}}</th>
            </tr>

            <tr>
                <td><span class="text-lime p-0">1</span>B72vFkLusk4LDuhPfPgUEUuWwK7Bxuwkf</td>
                <td><span class="text-red p-0">m</span>jYwhfFLX6k4UDij1FMVpd9zckvs87saUT</td>
            </tr>

            <tr>
                <td><span class="text-lime p-0">3</span>362M2DYXJ3jn3ndVLvJ33SWij6J1kA4Gu</td>
                <td><span class="text-red p-0">2</span>N9wfnrpPoNzTQP3GToMT4MqAiBg3woV2At</td>
            </tr>

            <tr>
                <td><span class="text-lime p-0">bc1</span>qdm2444vav9hvwewfqyzm0zpkphl6relz56t024</td>
                <td><span class="text-red p-0">tb1</span>q93qdwrvdrw0mftgesyd0c9pk3ty6fp29duh6tu</td>
            </tr>
        </tbody>
    </table>

    <br>

    <p class="descParagraph">
        {{'aboutTestnetScams.walletGeneratorDesc' | translate}}
    </p>

    <img src="../../../assets/bitcoin-network-select.webp" class="networkSelectImg">

    <div style="margin-top: 75px"></div>

</div>

