<div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
    <div style="width: 70%">
        <h1>{{'آموزش استفاده از نرم افزار ' | translate}}</h1>
        <p class="">
          
			در مرحله اول کیف پول الکتروم (Electrum) را از مارکتهای معتبر مانند گوگل پلی یا کافه بازار یا آدرس وب سایت خود پروژه این کیف پول به آدرسهای Electrum.org (http://electrum.org/) بنا بر نوع دستگاه خود (ویندوز یا اندروید) دانلود کنید
			<p>

‌مرحله دوم آدرس سایت 8key.ir (http://8key.ir/) را باز کنید و مطابق تصویر زیر آدرس و کلید خصوصی را بیابید : 
<p>

‌مثال : وارد لینک زیر شوید : 
<p>

‌http://8key.ir/bitcoin/101
<p>

در کانتر بالا با رنگ قرمز اعلام شده که  ۱۳۸ کلید بیتکوین مورد بررسی قرار گرفته که یکی از کلیدها دارای تراکنش بوده . کلید دارای تراکنش را پیدا میکنیم : 
<p>

به تصویر زیر توجه کنید :
<p>
			
            <br>
			<img src="../../assets/1.png">
            <br>
            
			حالا میدانیم عناصر مربوط به یک کیف پول در شبکه بیتکوین در نگاه اول به چه صورت است : 
			<p>
‌این کیف پول دارای دو کلید خصوصی و چهار آدرس (که در ادامه به معرفی نوع آدرسها میپردازیم) با مانده 0 و تعداد تراکنش چهار عددی در پیش روی ما است .
<p>
‌مرحله اول : دو کلید خصوصی را کپی میکنیم و وارد کیف پول الکتروم خود میشویم به ترتیب نوع دستگاه خود، مراحل زیر را انجام دهید : 
<p>
‌در بخش اول  بدون روشن کردن تیک
<p>
 Advance network setting
 <p>
 ( که مربوط که کانفیگ شبکه شماست) با زدن روی دکمه Next وارد مرحله دوم شده و یک اسم برای کیف پول خود انتخاب میکنید 
 <p>
پس از انتخاب نام گزینه Next را وارد کرده و چهار بخش که با دکمه های رادیویی از هم جدا شده را مشاهده میکنید 
<p>
آخرین گزینه یعنی 
<p>
import bitcoin address or private key
<p>
را روشن کنید و با زدن دکمه Next وارد مرحله بعد شوید .
<p>
در این مرحله یک باکس مشخص شده و دو کیف پول خود که در مرحله اول کپی کرده بودیم را Paste میکنیم (در هر خط یک کلید خصوصی ) و با ضربه به دکمه Next وارد مرحله بعد شوید .
<p>
در مرحله بعد یک رمز عبور برای کیف پول خود انتخاب کنید (هرچقدر امن تر بهتر) 
<p>
و در انتها با ضربه زدن به دکمه FINISH اجازه دهید چهار تراکنش مربوط به این کیف پول لود شود . 
<p>
حالا شما مالک این کیف پول هستید و همانطور که مشاهده میکنید دو واریز و دو برداشت در بیستم برج دوازده سال ۲۰۰۸ در این کیف پول ثبت شده است . 
<p>
و پس از آن کیف پول خالی رها شده و ما توانستیم همین کیف پول رها شده را آنالیز کنیم . 
<p>
حالا وارد سایت 8key.ir (http://8key.ir/) شوید و با روشن کردن خزنده آنقدر بگردید تا ک
<p>
یف پول های دارای بیتکوین که در سالهای دور رها شده را پیدا کنید .
<p>
اما وقتی کیف پول پیدا شد یادتان باشد که ما این بستر را برای شما محیا کردیم و پس از برداشت گنج خود بعنوان تشکر و سرمایه گذاری جمعی هرچقدر دوست داشتید (بیتکوین) به کیف پول ما به آدرس :
<p>
bc1qp8u36u0g4h3s9xedvcffpjf58eeg4ss5uya0vz
<p>
واریز کنید 
<p>
تا هم ما متوجه شویم که شما برداشت داشتید و خوشحال شویم و هم با مبلغی که برای ما می‌فرستید امکانات نرم افزاری و سخت افزاری بیشتری برای راحتی شما در پیدا کردن گنج در این زنجیره هوشمند تدارک ببینیم .
			
			
            <br>
            <br>
                <br>
                <br>
                
                <br>
        </p>

        <div style="margin-top: 100px"></div>
    </div>
</div>
