<h2>{{'شبکه خود را انتخاب کنید ' | translate}}</h2>
<div class="btn-group" (change)="onRadioButtonChanged()">
    <input type="radio" [name]="radioName" [formControl]="networkFormControl" [value]="networkTypes.bitcoin" class="btn-check" [id]="'networkBtcRadio'+radioName" autocomplete="off">
    <label class="btn btn-outline-primary" [for]="'networkBtcRadio'+radioName">{{'شبکه اصلی بیت‌کوین' | translate}}</label>
  
    <input type="radio" [name]="radioName" [formControl]="networkFormControl" [value]="networkTypes.testnet" class="btn-check" [id]="'networkTestnetRadio'+radioName" autocomplete="off">
    <label class="btn btn-outline-primary" [for]="'networkTestnetRadio'+radioName">
	
	
	{{'شبکه تست‌نت بدون دارایی' | translate}}</label>
</div>
