<h2>{{'فرمت آدرس ایجاد شده' | translate}}</h2>
<div class="btn-group" role="group" (change)="onRadioButtonChanged()">
    <input type="radio" [name]="radioName" [formControl]="addressFormControl" [value]="addressTypes.legacy" class="btn-check" [id]="'legacyRadio'+radioName" autocomplete="off">
    <label class="btn btn-outline-primary" [for]="'legacyRadio'+radioName">لیجیسی</label>
  
    <input type="radio" [name]="radioName" [formControl]="addressFormControl" [value]="addressTypes.legacyCompressed" class="btn-check" [id]="'legacyCompressedRadio'+radioName">
    <label class="btn btn-outline-primary" [for]="'legacyCompressedRadio'+radioName">لیجیسی (کامپرس شده.)</label>
    
    <input type="radio" [name]="radioName" [formControl]="addressFormControl" [value]="addressTypes.segwit" class="btn-check" [id]="'segwitRadio'+radioName">
    <label class="btn btn-outline-primary" [for]="'segwitRadio'+radioName">سگ-ویت</label>

    <input type="radio" [name]="radioName" [formControl]="addressFormControl" [value]="addressTypes.bech32" class="btn-check" [id]="'bech32Radio'+radioName">
    <label class="btn btn-outline-primary" [for]="'bech32Radio'+radioName">بی-ای-سی-اچ۳۲</label>
</div>