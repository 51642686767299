<div style="width: 100%; display: flex; flex-direction: row; justify-content: center;">
    <div style="width: 75%">
        <h1>{{'درباره ما'}}</h1>
        <p class="descParagraph">
       
	   این وب سایت برای مدتی در حال توسعه است و نمونه ای که مشاهده میکنید ممکن است در روزهای آینده تغییرات زیادی داشته باشد.
	   <p>
‌ایده "هش کی" یا " آزمایشگاه زنجیره هوشمند " برگرفته از پروژه directory.io (http://directory.io/) (که دیگر وجود ندارد) ایجاد شد که انگیزه شروع را به مجموعه رسا مدیا سازان نیک راد راد داد تا با ساخت نسخه های اولیه که دارای ویژگیهای پیشرفته زیادی مانند بررسی سریع و کاربر پسند به همراه انتخاب صفحه در دو حالت اتوماتیک و دستی، ورژن بومی این نرم افزار را برای جویندگان گنج های الکترونیک فراهم آوریم.
 <p>
‌تولید کلیدهای خصوصی 
 <p>
‌کلیدهای خصوصی با استفاده از الگوریتم زیر تولید می شوند. به عنوان مثال، اگر صفحه 100 را در نظر بگیریم، اولین دانه این است: (100 - 1) * 128 + 0 = 12672و آخرین آن: (100 - 1) * 128 + 127 = 12799به عنوان صفحات حاوی 128 کلید است. این کار آنقدر تکرار می شود تا به حداکثر کلیدهای خصوصی بیت‌کوین برسیم.
 <p>
‌همچنین موجودی ها هم با استفاده از API های بلاکچین در لحظه چک میشود . 
 <p>
‌جستجوگر کلیدهای خصوصی بیت کوین 
 <p>
‌این برنامه از جستجوهای کلید خصوصی پشتیبانی می کند که صفحه دقیقی را که یک کلید در آن قرار دارد نشان می دهد.
 <p>
‌اگر کنجکاو هستید که یک کلید خصوصی در کجای فضای کلید قرار داده شده است، می توانید اینجا جستجو کنید. توجه داشته باشید که تایپ کلید خصوصی خود که دارای اعتبار است حتی در این وب‌سایت هم در وب سایت هم کاری خطرناک است!  
‌پس اگر میخواهید عملکرد مربوط به نرمافزار ما را بسنجید از کیف پول های بدون اعتبار استفاده کنید!
 <p>
‌این یک ابزار عالی برای یافتن مکانی است که کلیدهای خصوصی دارای اعتبار در شبکه بیت کوین رهاشده است! 
 <p>
‌بک‌اند
 <p>
‌کلیدهای خصوصی فقط اعدادی در محدوده 1 و 2 256 هستند . اگر بخواهیم درک صحیحی از این عدد داشته باشیم با احتساب اینکه در هر صفحه ۱۲۸ کلید مورد بررسی قرار میگیرد چیزی در حدود ۷۳ رقم با شروع عدد ۳۴ صورت میپذیرد لطفاً این تصویر را مشاهده کنید . 
‌ <p>
خدمات آزمایشگاه شبکه هوشمند 
 <p>
‌ این وب سایت تمام کلیدهای خصوصی ممکن را با تقسیم آنها در هر صفحه ۱۳۸ کلید نمایش می دهد.
 <p>
‌
 خیالتان راحت باشد ما هیچوقت نه میخواهیم نه میتوانیم  کلیدهایی که شما مشاهده میکنید را در دیتابیسی ذخیره کنیم  زیرا این امر به دلیل نیاز به  فضای نامحدود دیسک و منابع بیش از هزار دیتاسنتری عملا غیرممکن است. 
  <p>
‌در عوض، کلیدهای خصوصی موجود در این نرم‌افزار تحت وب با شماره صفحه (احتمالا در نرم افزار های آرشیو ساز مانند  web.archive.org (http://web.archive.org/) یا سرچ موتورهای جستجوگر)  قابل رویت می‌باشد .
 <p>
            <br>
            <br>
            {{'
			' | translate}}
        </p>

        <div style="margin-top: 50px"></div>



        <div style="margin-top: 100px"></div>
    </div>
</div>
