<div class="d-flex align-items-center w-100 flex-column justify-content-center">

    <div class="mt-5"></div>
    <img src="../../assets/failed.webp">
    <h1>{{'notFound.topHeader' | translate}}</h1>
    <h2>{{'notFound.topSubheader' | translate}}</h2>

    <div class="mt-4"></div>

    <a class="btn btn-primary btn-lg" routerLink="/">{{'notFound.homepageBtn' | translate}}</a>
</div>