<div>
    <h5>{{'نمایش وضعیت کلید' | translate}}</h5>
    <div class="btn-group keyStatusDisplayType" role="group" aria-label="Stop On Yellow" (change)="onKeyStatusDisplayTypeRadioButtonChanged($event)">
    <input type="radio" value="sevenSegment" [formControl]="keyStatusDisplayType" class="btn-check" [id]="'keyStatusDisplaySevenSegment'+formName" autocomplete="off">
    <label class="btn btn-outline-primary" [for]="'keyStatusDisplaySevenSegment'+formName" [ngClass]="{'active' : keyStatusDisplayType.value == types.sevenSegment}">{{'پیشفرض' | translate}}</label>

    <input type="radio" value="text" [formControl]="keyStatusDisplayType" class="btn-check" [id]="'keyStatusDisplayText'+formName" autocomplete="off">
    <label class="btn btn-outline-primary" [for]="'keyStatusDisplayText'+formName" [ngClass]="{'active' : keyStatusDisplayType.value == types.text}">{{'نوشته' | translate}}</label>

    <input type="radio" value="off" [formControl]="keyStatusDisplayType" class="btn-check" [id]="'keyStatusDisplayOff'+formName" autocomplete="off">
    <label class="btn btn-outline-primary" [for]="'keyStatusDisplayOff'+formName" [ngClass]="{'active' : keyStatusDisplayType.value == types.off}">{{'خاموش' | translate}}</label>
</div>