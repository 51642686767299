<div class="container">
    <h1>{{'آزمایشگاه زنجیره هوشمند' | translate}}</h1>    

    <div class="m-5"></div>

    <!--Raw Angular Source Code-->
    <h2><a class="whiteLink sourceCodeLink" target="_blank" href="https://github.com/ICARUS-2/ElliptiKeys-ng/"> {{'sourceCode.rawCodeHeader' | translate}} </a></h2>
    <p class="sourceCodeDescParagraph"> 
        {{'sourceCode.rawCodeDesc' | translate}} 
        <a href="https://angular.io/cli" class="whiteLink sourceCodeLink" target="_blank">Angular CLI.</a>
    </p>
    <img src="../../../assets/angular-source-code.webp" class="sourceCodeImg">

    <div class="m-5"></div>

    <!--Precompiled Build-->
    <h2><a class="whiteLink sourceCodeLink" target="_blank" href="https://github.com/ElliptiKeys/ElliptiKeys.github.io">{{'sourceCode.precompiledBuildHeader' | translate}}</a></h2>
    <p class="sourceCodeDescParagraph"> 
        {{'sourceCode.precompiledBuildDesc' | translate}}
        <a href="https://marketplace.visualstudio.com/items?itemName=ritwickdey.LiveServer" target="_blank" class="whiteLink sourceCodeLink">{{'sourceCode.liveServerLink' | translate}}</a>
    </p>
    <img src="../../../assets/precompiled-source-code.webp" class="sourceCodeImg">

    <div class="m-5"></div>

    <!--ElliptiKeysJS-->
    <h2><a class="whiteLink sourceCodeLink" target="_blank" href="https://github.com/ICARUS-2/ElliptiKeys-lib">{{'sourceCode.elliptikeysJsHeader' | translate}}</a></h2>
    <p class="sourceCodeDescParagraph">
        {{'sourceCode.elliptikeysJsDesc' | translate}}
    </p>
    <img src="../../../assets/elliptikeysjs-sourcecode.webp" class="sourceCodeImg">

    <div style="margin-top: 75px"></div>

</div>
