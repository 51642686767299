<div class="settingsPageContainer container">
    <h1>{{'تنظیمات' | translate}}</h1>

    <div class="m-4"></div>

    <h2>{{'صفحه کلیدها' | translate}}</h2>
    <app-hide-unused-keys></app-hide-unused-keys>
    <br>
    <app-key-status-display-type></app-key-status-display-type>

    <div class="m-4"></div>

    <h2>اتوماتیک</h2>
    <app-auto-gen-config></app-auto-gen-config>
</div>