<div style="float:right; width: 100%; display: flex; flex-direction: row; justify-content: center;">
    <div style="width: 90%;text-align:right;font-family:tahoma;" >
        <h4>{{"همه کلیدهای خصوصی شبکه بیت‌کوین اینجاست" | translate}}</h4>
        <h5>{{"به زبان ساده باید گفت تمامی کلیدهای خصوصی کیف پولهای موجود در شبکه بیت‌کوین  در این سایت را میتوان یافت " | translate}}</h5>
<h5> با توجه به گستردگی تعداد کلید های خصوصی فابل ایجاد در این شبکه با استفاده از برخی ابزارها مثل خزنده اتوماتیک، احتمال رسیدن به کیف های پول دارای اعتبار بیشتر خواهد شد</h5>
        <div class="m-5"></div>
        
        <div id="networkSelector">
            <div class="randomPageSelector d-flex flex-column align-items-center m-2">
                <img src="../../assets/bitcoin-logo.webp" class="randomBitcoinImage">
                <a routerLink="/random/" class="btn btn-lg btn-primary">{{'ورود به شبکه اصلی' | translate}}</a>
            </div>


        </div>


        <div class="m-5"></div>

        
       
    

        <div class="m-5"></div>

 
    </div>
</div>