<div>
    <h5>{{'چشم‌پوشی از ولتهای خالی' | translate}}</h5>

    <div class="btn-group" role="group" (change)="onHideUnusedComponentRadioButtonChanged($event)">
        <input id="settingsHideUnusedKeysTrue" type="radio" name="settingsHideUnusedKeys" [formControl]="hideUnusedKeysFormControl" value=true class="btn-check" autocomplete="off">
        <label class="btn btn-outline-primary" [ngClass]="{'active' : hideUnusedKeysFormControl.value}" for="settingsHideUnusedKeysTrue">{{'بله' | translate}}</label>
        
        <input id="settingsHideUnusedKeysFalse" type="radio" name="settingsHideUnusedKeys" [formControl]="hideUnusedKeysFormControl" value=false class="btn-check" autocomplete="off">
        <label class="btn btn-outline-primary" [ngClass]="{'active' : !hideUnusedKeysFormControl.value}" for="settingsHideUnusedKeysFalse">{{'خیر' | translate}}</label>
    </div>
</div>