<div>
    <div *ngIf="getCurrentDisplayType() == displayTypes.text">
        🔴: {{redCounter}} 🟡: {{yellowCounter}} 🟢: {{greenCounter}}
    </div>
    <div class="tripleSevenSegmentDisplay" *ngIf="getCurrentDisplayType() == displayTypes.sevenSegment">
        <div class="sevenSegmentWithFooter">
            <seven-seg [digits]=3 [value]="redCounter"></seven-seg>
            <div>🔴</div>
        </div>

        <div class="sevenSegmentWithFooter middleDisplay">
            <seven-seg [digits]=3 [value]="yellowCounter"></seven-seg>
            <div>🟡</div>
        </div>

        <div class="sevenSegmentWithFooter">
            <seven-seg [digits]=3 [value]="greenCounter"></seven-seg>
            <div>🟢</div>
        </div>
    </div>
</div>
